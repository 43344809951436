



















































































































































import { Component, Watch } from 'vue-property-decorator'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { AuthMixin } from '@/mixins/auth.mixin'

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import Field from '@/components/forms/Field.vue'
import Modal from '@/components/Modal.vue'

const validations = {
  registerModel: {
    name: { required, minLength: minLength(1) },
    email: { required, email },
    phone: { required },
  },
  terms: { required, sameAs: sameAs(() => true) },
}

@Component({
  components: { FormGroup, Field, BaseButton, BaseCheckbox, Modal },
  metaInfo: {
    title: 'Регистрация',
  },
  validations,
})
export default class RegisterPage extends AuthMixin {
  modalButtonActive = false

  @Watch('showModal')
  onModalShow(val: boolean): void {
    if (val) {
      setTimeout(() => {
        this.modalButtonActive = true
      }, 7000)
    }
  }
}
